@charset "UTF-8";
.offcanvas-body .side-links .nav-link {
  text-align: right;
}

.events .slick-next {
  right: -5px;
}
.events .slick-next::before {
  color: #000;
}
.events .slick-prev {
  left: -5px;
}
.events .slick-prev::before {
  color: #000;
}
.events .slick-list {
  margin: 0 20px;
}
.events .custom-card {
  display: flex;
  justify-content: center;
  align-items: center;
}
.events .custom-card h3 {
  text-align: center;
}

.parteners .slick-next {
  right: -5px;
}
.parteners .slick-next::before {
  color: #000;
}
.parteners .slick-prev {
  left: -5px;
}
.parteners .slick-prev::before {
  color: #000;
}
.parteners .slick-list {
  margin: 0 20px;
}
.parteners .custom-card {
  display: flex !important;
  justify-content: center;
  align-items: center;
}
.parteners .custom-card h3 {
  text-align: center;
}

.global-hero {
  width: 100%;
  background-attachment: fixed;
  position: relative;
  height: 650px;
}
.global-hero .hero {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.global-hero .hero p {
  color: #fff;
  text-shadow: 4px 4px #000;
}
.global-hero .hero .buttons {
  display: flex;
  justify-content: center;
  align-items: center;
}
.global-hero .video-wrap {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  z-index: -3;
  background-color: #196395;
}
.global-hero .video-wrap #video {
  width: 100% !important;
  height: 100% !important;
  object-position: center center;
}
.global-hero .video-wrap .video-overlay {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  z-index: 1;
  background-color: rgba(0, 0, 0, 0);
}

.navbar {
  flex-wrap: nowrap;
}

@media (max-width: 992px) {
  .global-hero {
    height: 300px !important;
  }
  .global-hero .hero p {
    text-shadow: 2px 2px #000;
  }
  .global-hero .video-wrap #video {
    object-fit: cover;
  }
  #watsLink #watsIconstyle {
    width: 40px !important;
    height: 40px !important;
  }
  .custom-card .responsive-image {
    height: 250px !important;
  }
  .custom-card p {
    text-align: center !important;
  }
  .footer {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
  .res-col {
    height: 250px !important;
  }
  .om {
    flex-direction: column !important;
  }
  .om img {
    order: 1;
  }
  .om .card-body {
    order: 2;
  }
}
@media (max-width: 768px) {
  .res-anim {
    width: 90%;
    height: 80px;
  }
}
@media (min-width: 992px) {
  .w-lg-50 {
    width: 50% !important;
  }
}
@media (max-width: 767px) {
  .offcanvas {
    width: 80%;
  }
}
.custom-f {
  font-size: 13px;
  color: #707070;
}

.custom-p {
  width: 100%;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.text-right {
  text-align: right;
}

.side-links {
  list-style: none;
  padding-left: 10px;
}
.side-links a {
  text-decoration: none;
  color: rgba(0, 0, 0, 0.7);
}

.footer {
  padding: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  background-color: #d7d9da;
}
.footer p {
  flex-grow: 1;
  text-align: center;
  font-weight: 700;
  font-size: 14px;
  color: #716f6f;
}

.sociallinks {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px;
}
.sociallinks a {
  text-decoration: none;
  color: #0a66c2;
}
.sociallinks a i {
  font-size: 25px;
}

#custom-he {
  height: auto !important;
}

#flex-grow {
  flex-grow: 1;
}

.AdministrativeTeam {
  flex-grow: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.AdministrativeTeam .slick-next {
  right: -5px;
}
.AdministrativeTeam .slick-next::before {
  color: #000;
}
.AdministrativeTeam .slick-prev {
  left: -5px;
}
.AdministrativeTeam .slick-prev::before {
  color: #000;
}
.AdministrativeTeam .slick-list {
  margin: 0 20px;
}
.AdministrativeTeam .head {
  display: flex;
  justify-content: center;
  align-items: center;
}
.AdministrativeTeam .stork1 {
  -webkit-text-stroke: 1px #1d9bf0;
  color: #fff;
}
.AdministrativeTeam .stork2 {
  -webkit-text-stroke: 1px #7190a4;
  color: #fff;
}
.AdministrativeTeam .card-team {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 10px;
  border-radius: 5px;
  border: 2px solid #d7d9da;
  color: #fff;
  background-color: #716f6f;
}
.AdministrativeTeam .card-team.heading {
  background-color: #1d9bf0;
}
.AdministrativeTeam .card-team > img {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  object-fit: cover;
  object-position: center top;
}
.AdministrativeTeam .card-team hr {
  height: 2px;
  background-color: #716f6f;
}

.AdvisoryBoard {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.AdvisoryBoard .stork1 {
  -webkit-text-stroke: 1px #1d9bf0;
  color: #fff;
}
.AdvisoryBoard .stork2 {
  -webkit-text-stroke: 1px #7190a4;
  color: #fff;
}
.AdvisoryBoard .head {
  display: flex;
  justify-content: center;
  align-items: center;
}
.AdvisoryBoard .card-team {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 10px;
  border-radius: 5px;
  border: 2px solid #d7d9da;
  color: #fff;
  background-color: #7190a4;
}
.AdvisoryBoard .card-team.heading2 {
  background-color: #716f6f;
}
.AdvisoryBoard .card-team img {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  object-fit: cover;
  object-position: center top;
}
.AdvisoryBoard .card-team hr {
  height: 2px;
  background-color: #716f6f;
}

.singls {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.singls > p {
  color: #0a66c2;
  font-weight: 700;
}
.singls .row .card-aod {
  color: #fff;
}
.singls .row .card-aod div {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 15px;
  border-radius: 5px;
  border: 1px solid #d7d9da;
  transition: 0.5s;
}
.singls .row .card-aod div:hover {
  box-shadow: 0 0 2px 0 #000;
  transform: scale(1.1);
}
.singls .row .card-aod div .h-r {
  width: 100%;
  height: 2px;
  background-color: #0a66c2;
}
.singls .row .card-aod div .advantages li {
  padding-right: 5px;
  font-size: 16px;
}
.singls .row .card-aod div .advantages li::marker {
  content: "✓";
}
.singls .row .card-aod:nth-child(1) div {
  background-color: #093957;
}
.singls .row .card-aod:nth-child(2) div {
  background-color: #166294;
}
.singls .row .card-aod:nth-child(3) div {
  background-color: #1884c2;
}

.companys {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.companys > p {
  color: #0a66c2;
  font-weight: 700;
}
.companys .slick-next {
  right: -5px;
}
.companys .slick-next::before {
  color: #000;
}
.companys .slick-prev {
  left: -5px;
}
.companys .slick-prev::before {
  color: #000;
}
.companys .slick-list {
  margin: 0 20px;
}
.companys .card-aod {
  color: #fff;
}
.companys .card-aod div {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 15px;
  border-radius: 5px;
  border: 1px solid #d7d9da;
}
.companys .card-aod div .h-r {
  width: 100%;
  height: 2px;
  background-color: #0a66c2;
}
.companys .card-aod div .advantages {
  direction: rtl;
}
.companys .card-aod div .advantages li {
  padding-right: 5px;
  font-size: 16px;
}
.companys .card-aod div .advantages li::marker {
  content: "✓";
}
.companys .mas {
  background-color: #adb4e1;
}
.companys .gold {
  background-color: #ba8a18;
}
.companys .fada {
  background-color: #babab9;
}
.companys .basic {
  background-color: #1884c2;
}

.shrot {
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;
  background-color: #707070;
  color: #fff;
  padding: 15px;
  border-radius: 5px;
  border: 2px solid #d7d9da;
  animation: anim forwards 0.3s linear 1;
  text-align: right;
}
@keyframes anim {
  from {
    transform: translate(-50%, -10%);
  }
  to {
    transform: translate(-50%, -50%);
  }
}
.shrot h6 i {
  color: #e4162a;
}
.shrot p i {
  margin-left: 3px;
  color: rgb(155, 47, 47);
}
.shrot .closeing {
  position: absolute;
  top: 10px;
  left: 10px;
  font-size: 30px;
  cursor: pointer;
}
.shrot .closeing:hover {
  color: red;
}

.services {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.services .cover {
  width: fit-content;
  margin: auto;
  color: #fff;
  border-radius: 5px;
  background-color: #166294;
}
.services .cover:last-of-type {
  background-color: #053260;
}
.services hr {
  width: 100%;
  height: 3px;
  background-color: #053260;
}
.services .row:nth-child(5) div .card .card-header {
  background-color: #053260;
}
.services .row:nth-child(5) div .card a {
  background-color: #053260;
}
.services .row div .card .card-header {
  background-color: #166294;
  color: white;
}
.services .row div .card a {
  display: block;
  background-color: #166294;
}
.services > .card {
  display: flex;
  flex-direction: row;
}
.services > .card .card-header {
  background-color: #4d4b4b;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
}
.services > .card a {
  display: block;
}

.watsapp {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.watsapp .cover {
  width: fit-content;
  margin: auto;
  color: #fff;
  border-radius: 5px;
  background-color: #3edf5b;
}
.watsapp hr {
  width: 100%;
  height: 3px;
  background-color: #3edf5b;
}
.watsapp .row div .card .card-header {
  background-color: #3edf5b;
  color: white;
}
.watsapp .row div .card .card-body p {
  text-align: right;
  line-height: 1.6;
}
.watsapp .row div .card a {
  display: block;
  background-color: #3edf5b;
}
.watsapp > .card {
  display: flex;
  flex-direction: row;
}
.watsapp > .card .card-header {
  background-color: #3edf5b;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
}
.watsapp > .card .card-body p {
  text-align: right;
  line-height: 1.6;
}
.watsapp > .card a {
  display: block;
  background-color: #3edf5b;
}

.Contact {
  flex-grow: 1;
  display: flex;
  justify-content: center;
}
.Contact .row .right {
  padding: 15px;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: #716f6f;
  color: #fff;
}
.Contact .row .right a {
  text-decoration: none;
  padding: 10px;
  border-radius: 4px;
  color: #fff;
  padding: 0;
  margin: 0;
  margin-right: auto;
}
.Contact .row .right .con {
  width: 100%;
  font-size: 16px;
  text-align: end;
}
.Contact .row .right iframe {
  margin: 0;
  width: 100%;
}
.Contact .row .right .sociallinks {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.Contact .row .right .sociallinks a {
  text-decoration: none;
  color: #0a66c2;
}
.Contact .row .right .sociallinks a i {
  font-size: 25px;
}
.Contact .row .left form {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.Contact .row .left form p {
  background-color: #716f6f;
  padding: 10px;
  border-radius: 4px;
  color: #fff;
}
.Contact .row .left form input {
  width: 49%;
}
.Contact .row .left form textarea {
  width: 100%;
  flex-grow: 1;
}
.Contact .row .left form .btn-cuton {
  color: #fff;
  background-color: #716f6f;
  cursor: pointer;
  width: 50%;
}

.privacy {
  flex-grow: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.privacy > p {
  padding: 10px;
  border-radius: 5px;
  background-color: #716f6f;
  color: white;
}
.privacy .row {
  padding: 10px;
  border-radius: 10px;
  text-align: center;
  background-color: #d7d9da;
}
.privacy .row article {
  direction: rtl;
  text-align: right;
}

.animatedLog {
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  background-color: #fff;
  z-index: 10;
  display: flex;
  justify-content: center;
  align-items: center;
}
.animatedLog.active {
  animation: anim2 0.5s forwards linear;
}
.animatedLog img {
  animation: animm 0.5s forwards linear;
}
@keyframes animm {
  from {
    transform: scale(0);
  }
  to {
    transform: scale(1);
  }
}
@keyframes anim2 {
  to {
    opacity: 0;
  }
}

li {
  text-align: right !important;
}

.Application {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  z-index: 10;
  background-color: rgba(0, 0, 0, 0.5);
}
.Application img {
  width: 250px;
  height: 600px;
}
.Application .clossss {
  font-size: 25px;
  color: #fff;
  cursor: pointer;
}
.Application .clossss:hover {
  color: red;
}

.main-title {
  text-transform: uppercase;
  margin: 0 auto 80px;
  border: 2px solid black;
  padding: 10px 20px;
  font-size: 30px;
  width: fit-content;
  position: relative;
  z-index: 1;
  transition: 0.2s;
}
.main-title::before {
  content: "";
  width: 12px;
  height: 12px;
  background-color: #1884c2;
  position: absolute;
  border-radius: 50%;
  top: 50%;
  transform: translateY(-50%);
  left: -30px;
  transition: 0.4s;
  z-index: -1;
}
.main-title::after {
  content: "";
  width: 12px;
  height: 12px;
  background-color: #1884c2;
  position: absolute;
  border-radius: 50%;
  top: 50%;
  transform: translateY(-50%);
  right: -30px;
  z-index: -1;
  transition: 0.4s;
}
.main-title:hover {
  color: white;
  border: 2px solid white;
  transition-delay: 0.5s;
}
.main-title:hover::before {
  left: 0;
  width: 50%;
  height: 100%;
  border-radius: 0;
}
.main-title:hover::after {
  right: 0;
  width: 50%;
  height: 100%;
  border-radius: 0;
}

#linkw {
  text-decoration: none;
}

.Says {
  flex-grow: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.Says .row {
  background-color: #716f6f;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 5px 20px;
}
.Says .row .slick-next {
  right: -5px;
}
.Says .row .slick-next::before {
  color: #000;
}
.Says .row .slick-prev {
  left: -5px;
}
.Says .row .slick-prev::before {
  color: #000;
}
.Says .row .slick-list {
  margin: 0 20px;
}
.Says .row .custom-card {
  display: flex;
  justify-content: center;
  align-items: center;
}
.Says .row .custom-card h3 {
  text-align: center;
}
.Says .row .custom-card .custom-p {
  position: relative;
  width: fit-content;
  margin: auto;
  overflow: unset;
}
.Says .row .custom-card .custom-p::before {
  content: "";
  width: 50%;
  height: 2px;
  background-color: #0a66c2;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: -7px;
  z-index: 5;
}
.Says .row .custom-card .custom-pos {
  padding: 5px;
  border-radius: 5px;
  background-color: #166294;
  width: fit-content;
  margin: auto;
}
.Says .row .stars {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.Says .row .bi-star-fill {
  color: #f7ae00;
}

.second-title {
  background-color: #1884c2;
  padding: 5px;
  color: #fff;
  width: fit-content;
  margin-left: auto;
  margin-right: auto;
  transform: skew(-20deg);
}

.Aboutus .text-cu {
  color: #fff;
}
.Aboutus hr {
  width: 100%;
  height: 1px;
  background-color: #166294;
}
.Aboutus .row {
  justify-content: center;
}
.Aboutus .row .card {
  transition: 0.3s;
}
.Aboutus .row .card:hover {
  background-color: #1884c2;
  color: #fff;
}
.Aboutus .row div div .imgaim {
  margin: auto;
  width: 60%;
  height: 200px;
  background-color: transparent;
}
.Aboutus .row .goalicon {
  color: #0a66c2;
}
.Aboutus .om {
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  background-color: #716f6f;
}
.Aboutus .om img {
  width: 200px !important;
  height: 200px !important;
  background-color: #0a66c2;
}
.Aboutus .om .om {
  background-color: transparent;
}
.Aboutus .slick-next {
  right: -5px;
}
.Aboutus .slick-next::before {
  color: #000;
}
.Aboutus .slick-prev {
  left: -5px;
}
.Aboutus .slick-prev::before {
  color: #000;
}
.Aboutus .slick-list {
  margin: 0 20px;
}
.Aboutus .custom-card {
  display: flex;
  justify-content: center;
  align-items: center;
}
.Aboutus .custom-card h3 {
  text-align: center;
}
.Aboutus .step-progress {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
}
.Aboutus .step-progress span {
  display: block;
  width: 40px;
  height: 40px;
  line-height: -20px;
  font-size: 20px;
  border: 1px solid #0a66c2;
  border-radius: 50%;
  background-color: #fff;
  cursor: pointer;
}
.Aboutus .step-progress span.active {
  background-color: #0a66c2;
  color: #fff;
  font-size: 22px;
  line-height: -22px;
}
.Aboutus .step-progress::before {
  content: "";
  width: calc(100% - 40px);
  height: 2px;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  background-color: #1884c2;
  z-index: -1;
}

.dashboard {
  flex-grow: 1;
}
.dashboard .eventsHandle {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.dashboard .eventsHandle > div {
  padding: 20px;
  width: calc((100% - 50px) / 2);
}
.dashboard .eventsHandle > div img {
  width: 100%;
}

.form-bg {
  background-color: #6c757d;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.form-bg input, .form-bg textarea {
  width: 300px;
}
.form-bg textarea {
  height: 150px;
}
.form-bg button {
  cursor: pointer;
}

.handlers {
  position: relative;
}
.handlers i {
  position: absolute;
  right: 30px;
  top: 20px;
  cursor: pointer;
  color: #1d9bf0;
  font-size: 30px;
}
.handlers i:hover {
  color: red;
}

.formmember {
  background-color: #fff;
  color: #000;
  box-shadow: 0 0 3px 0 #000;
  padding: 15px;
  border-radius: 5px;
}
.formmember .isal {
  border: 1px solid #1d9bf0;
  width: 250px;
  height: 250px;
}
.formmember .title {
  color: #1d9bf0;
}
.formmember p i {
  color: #000;
}
.formmember form .custom-header {
  background-color: #1884c2;
  width: fit-content;
  color: #fff;
  margin-left: auto;
  margin-right: auto;
  border-radius: 5px;
}
.formmember form label {
  color: #1d9bf0;
}
.formmember form label span {
  color: red;
}
.formmember form input, .formmember form textarea {
  border: 1px solid #1884c2 !important;
  outline: none;
  color: #000;
}
.formmember form input:focus, .formmember form textarea:focus {
  outline: none;
  border: none;
}
.formmember form .form-select {
  background-position: left 0.75rem center;
}
.formmember .colss {
  position: absolute;
  left: 20px;
  top: 20px;
  color: #1d9bf0;
  font-size: 30px;
  cursor: pointer;
}
.formmember .colss:hover {
  color: red;
}

.Cv {
  flex-grow: 1;
}
.Cv .active-custom {
  border-radius: 5px;
  background-color: #1884c2;
  color: #fff !important;
}
.Cv .active-custom .nav-link {
  color: #fff !important;
}

.Addcv form label {
  color: #1d9bf0;
}
.Addcv form label span {
  color: red;
}
.Addcv form input {
  border: 1px solid #1884c2 !important;
  outline: none;
  color: #000;
}
.Addcv form input:focus {
  outline: none;
  border: none;
}
.Addcv form input::placeholder {
  color: #cbcccd;
}
.Addcv form .form-select {
  background-position: left 0.75rem center;
  border: 1px solid #1884c2 !important;
  outline: none;
}
.Addcv form textarea {
  border: 1px solid #1884c2 !important;
  outline: none;
}

.text-nowrap {
  white-space: nowrap;
}

.text-cuto {
  color: #166294;
}

.massage {
  background-color: #0a66c2;
  position: fixed;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0 0 5px 0 #000;
  color: #fff;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  transition: 0.4s;
}
.massage i {
  position: absolute;
  left: 2px;
  top: 2px;
  cursor: pointer;
}
.massage i:hover {
  color: red;
}
.massage.active {
  transform: translate(-500%, -50%);
}

.massage-customs {
  background-color: #0a66c2;
  position: fixed;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0 0 5px 0 #000;
  color: #fff;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  transition: 0.4s;
}
.massage-customs i {
  position: absolute;
  left: 2px;
  top: 2px;
  cursor: pointer;
}
.massage-customs i:hover {
  color: red;
}
.massage-customs.active {
  transform: translate(-500%, -50%);
}

.massage-custom {
  width: 300px;
  height: 340px;
  position: fixed;
  padding: 20px;
  border-radius: 5px;
  background-color: #1884c2;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  transition: 0.4s;
}
.massage-custom p {
  padding: 0;
  margin: 0;
}
.massage-custom video {
  width: 100%;
  height: 100%;
}
.massage-custom i {
  position: absolute;
  left: 5px;
  top: 5px;
  cursor: pointer;
}
.massage-custom i:hover {
  color: red;
}

.table-responsive {
  overflow: auto;
}
.table-responsive .table {
  overflow: auto;
}

.w-custom {
  width: 120px;
  white-space: nowrap;
}
.w-custom i {
  font-size: 15px;
}

.deleting {
  color: #1d9bf0;
  transition: 0.4s;
  cursor: pointer;
}
.deleting:hover {
  color: red;
  font-size: 1.2rem;
}

.search form .custom-header {
  background-color: #1884c2;
  width: fit-content;
  color: #fff;
  margin-left: auto;
  margin-right: auto;
  border-radius: 5px;
}
.search form label {
  color: #1d9bf0;
}
.search form label span {
  color: red;
}
.search form input, .search form textarea {
  border: 1px solid #1884c2 !important;
  outline: none;
  color: #000;
}
.search form input:focus, .search form textarea:focus {
  outline: none;
  border: none;
}
.search form .form-select {
  background-position: left 0.75rem center;
}

@media (min-width: 992px) {
  .w-lg-25 {
    width: 24% !important;
  }
}
.customeventForm {
  flex-grow: 1;
}
.customeventForm form label {
  color: #1d9bf0;
}
.customeventForm form label span {
  color: red;
}
.customeventForm form input {
  border: 1px solid #1884c2 !important;
  outline: none;
  color: #000;
}
.customeventForm form input:focus {
  outline: none;
  border: none;
}
.customeventForm form input::placeholder {
  color: #cbcccd;
}

.form-check {
  padding: 0;
  margin: 0;
}
.form-check .form-check-input {
  float: right;
  margin-right: -1.5em;
  cursor: pointer;
}

.Reports {
  flex-grow: 1;
  display: flex;
  justify-content: center;
}
.Reports .rep {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.Reports .rep .covering-image {
  position: relative;
  width: fit-content;
  height: fit-content;
}
.Reports .rep .covering-image img {
  width: 100%;
  max-height: 450px;
  object-fit: contain;
}
.Reports .rep .covering-image .contorl {
  position: absolute;
  bottom: 0;
  left: 0;
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3);
}
.Reports .rep .covering-image .contorl a {
  color: #fff;
}
.Reports .rep .covering-image .contorl a:hover {
  color: #1884c2 !important;
}
.Reports .rep .covering-image .contorl a:hover i {
  color: #1884c2 !important;
  font-size: 40px;
}
.Reports .rep .covering-image .contorl a i {
  color: #fff;
  font-size: 30px;
  transition: 0.4s;
}

#watsLink {
  position: fixed;
  bottom: 5px;
  right: 5px;
  color: #3edf5b;
  cursor: pointer;
  z-index: 10000;
  text-shadow: 0 0 2px #3edf5b;
  padding: 5px;
}
#watsLink #watsIconstyle {
  width: 60px;
  height: 60px;
}
#watsLink i {
  font-size: 50px;
}

.signupForm {
  flex-grow: 1;
  position: relative;
}
.signupForm form {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 300px;
}
.signupForm form div {
  position: relative;
}
.signupForm form div i {
  position: absolute;
  left: 5px;
  top: 50%;
  transform: translateY(-50%);
  font-size: 20px;
  cursor: pointer;
}

form div {
  flex-wrap: wrap !important;
}
form div div {
  width: 49%;
}
form div div.RSPBprogressBar {
  width: 100% !important;
}
@media (max-width: 992px) {
  form div div {
    width: 100%;
  }
  form div div.RSPBprogressBar {
    width: 100% !important;
  }
}

.imag-phone {
  object-fit: contain;
  object-position: center;
  width: 200px;
  height: 250px;
}
@media (max-width: 992px) {
  .imag-phone {
    width: 100%;
    height: 300px;
  }
}

.nobza-custom {
  max-height: 100px;
  max-width: 500px;
  overflow-x: auto;
}
.nobza-custom::-webkit-scrollbar {
  width: 4px;
}
.nobza-custom::-webkit-scrollbar-track {
  background-color: #000;
}
.nobza-custom::-webkit-scrollbar-thumb {
  background-color: #1884c2;
}