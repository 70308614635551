.offcanvas-body{
    .side-links{
        .nav-link{
            text-align: right;
        }
    }
}
.events{
    .slick-next{
        right: -5px;
        &::before{
            color: #000;
        }
    }
    .slick-prev{
        left: -5px;
        &::before{
            color: #000;
        }
    }
    .slick-list{
        margin: 0 20px;
    }
    .custom-card{
        display: flex;
        justify-content: center;
        align-items: center;
        h3{
            text-align: center;
        }
    }
}
.parteners{
    .slick-next{
        right: -5px;
        &::before{
            color: #000;
        }
    }
    .slick-prev{
        left: -5px;
        &::before{
            color: #000;
        }
    }
    .slick-list{
        margin: 0 20px;
    }
    .custom-card{
        display: flex !important;
        justify-content: center;
        align-items: center;
        h3{
            text-align: center;
        }
    }
}
.global-hero{
    width: 100%;
    background-attachment: fixed;
    position: relative;
    height: 650px;
    .hero{
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        p{
            color: #fff;
            // font-weight: 300 !important;
            text-shadow: 4px 4px #000;
        }
        .buttons{
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }
    .video-wrap{
        width: 100%;
        height: 100%;
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        z-index: -3;
        background-color: #196395;
        #video{
            width: 100% !important;
            height: 100% !important;
            // object-fit: cover;
            object-position: center center;
        }
        .video-overlay{
            position: absolute;
            left: 0;
            top: 0;
            height: 100%;
            width: 100%;
            z-index: 1;
            background-color: rgba(#000 , 0);
        }
    }
}
.navbar{
    flex-wrap: nowrap;
}
@media (max-width:992px) {
    .global-hero{
        height: 300px !important;
        .hero{
            p{
                text-shadow: 2px 2px #000;
            }
        }
        .video-wrap{
            #video{
                object-fit: cover;
            }
        }
    }
    #watsLink{
        #watsIconstyle{
            width: 40px !important;
            height: 40px !important;
        }
    }
    .custom-card{
        .responsive-image{
            height:250px !important;
        }
        p{
            text-align: center !important;
        }
    }
    // .part-img{
    //     img{
    //         width: 100px !important;
    //         height:150px !important;
    //     }
    // }
    .footer{
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
    }
    .res-col{
        height: 250px !important;
    }
    .om{
        flex-direction: column !important;
        img{
            order: 1;
        }
        .card-body{
            order: 2;
        }
    }
}
@media (max-width:768px) {
    .res-anim{
        width: 90%;
        height: 80px;
    }
}
@media (min-width:992px){
    .w-lg-50{
        width: 50% !important;
    }
}
@media (max-width:767px){
    .offcanvas{
        width: 80%;
    }
}
.custom-f{
    font-size: 13px;
    color: #707070;
}
.custom-p{
    width: 100%;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}
.text-right{
    text-align: right;
}
.side-links{
    list-style: none;
    padding-left: 10px;
    a{
        text-decoration: none;
        color: rgba(0,0,0,.7);
    }
}
.footer{
    padding: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    // height: 80px;
    background-color: #d7d9da;
    p{
        flex-grow: 1;
        text-align: center;
        font-weight: 700;
        font-size: 14px;
        color: #716f6f;
    }
}
.sociallinks{
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 5px;
    a{
        text-decoration: none;
        color: #0a66c2;
            i{
                font-size: 25px;
            }
    }
}
#custom-he{
    height: auto !important;
}
#flex-grow{
    flex-grow: 1;
}
.AdministrativeTeam{
    flex-grow: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    .slick-next{
        right: -5px;
        &::before{
            color: #000;
        }
    }
    .slick-prev{
        left: -5px;
        &::before{
            color: #000;
        }
    }
    .slick-list{
        margin: 0 20px;
    }
    .head{
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .stork1{
        -webkit-text-stroke: 1px #1d9bf0;
        color: #fff;
    }
    .stork2{
        -webkit-text-stroke: 1px #7190a4;
        color: #fff;
    }
    .card-team{
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        padding: 10px;
        border-radius: 5px;
        border: 2px solid #d7d9da;
        color: #fff;
        background-color: #716f6f;
        &.heading{
            background-color: #1d9bf0;
        }
        >img{
            width: 100px;
            height: 100px;
            border-radius: 50%;
            object-fit: cover;
            object-position: center top;
        }
        hr{
            height: 2px;
            background-color: #716f6f;
        }
    }
}
.AdvisoryBoard{
    .stork1{
        -webkit-text-stroke: 1px #1d9bf0;
        color: #fff;
    }
    .stork2{
        -webkit-text-stroke: 1px #7190a4;
        color: #fff;
    }
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    .head{
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .card-team{
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        padding: 10px;
        border-radius: 5px;
        border: 2px solid #d7d9da;
        color: #fff;
        background-color: #7190a4;
        &.heading2{
            background-color: #716f6f;
        }
        img{
            width: 100px;
            height: 100px;
            border-radius: 50%;
            object-fit: cover;
            object-position: center top;
        }
        hr{
            height: 2px;
            background-color: #716f6f;
        }
    }
}
.singls{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    >p{
        // -webkit-text-stroke: 1px #0a66c2;
        color:  #0a66c2;
        font-weight: 700;
    }
    .row{
        .card-aod{
            color: #fff;
            div{
                display: flex;
                justify-content: center;
                align-items: center;
                flex-direction: column;
                padding:15px;
                border-radius: 5px;
                border: 1px solid #d7d9da;
                transition: 0.5s;
                &:hover{
                    box-shadow: 0 0 2px 0 #000;
                    transform: scale(1.1);
                }
                .h-r{
                    width: 100%;
                    height: 2px;
                    background-color: #0a66c2;
                }
                .advantages{
                    li{
                        padding-right: 5px;
                        font-size: 16px;
                    }
                    li::marker {
                        content: '✓';
                    }
                }
            }
            &:nth-child(1){
                div{
                    background-color: #093957;
                }
            }
            &:nth-child(2){
                div{
                    background-color: #166294;
                }
            }
            &:nth-child(3){
                div{
                    background-color: #1884c2;
                }
            }
        }
    }
}
.companys{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    >p{
        // -webkit-text-stroke: 1px #0a66c2;
        color: #0a66c2;
        font-weight: 700;
    }
    .slick-next{
        right: -5px;
        &::before{
            color: #000;
        }
    }
    .slick-prev{
        left: -5px;
        &::before{
            color: #000;
        }
    }
    .slick-list{
        margin: 0 20px;
    }
    .card-aod{
        color: #fff;
        div{
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            padding:15px;
            border-radius: 5px;
            border: 1px solid #d7d9da;
            // transition: 0.5s;
            // &:hover{
            //     box-shadow: 0 0 2px 0 #000;
            //     transform: scale(1.1);
            // }
            .h-r{
                width: 100%;
                height: 2px;
                background-color: #0a66c2;
            }
            .advantages{
                direction: rtl;
                li{
                    padding-right: 5px;
                    font-size: 16px;
                }
                li::marker {
                    content: '✓';
                }
            }
        }
    }
    .mas{
        background-color: #adb4e1;
    }
    .gold{
        background-color: #ba8a18;
    }
    .fada{
        background-color: #babab9;
    }
    .basic{
        background-color: #1884c2;
    }
}
.shrot{
    position: fixed;
    left: 50%;
    top: 50%;
    transform: translate(-50%,-50%);
    z-index: 1;
    background-color: #707070;
    color: #fff;
    padding: 15px;
    border-radius: 5px;
    border: 2px solid #d7d9da;
    animation: anim forwards 0.3s linear 1;
    text-align: right;
    @keyframes anim {
        from{
            transform: translate(-50%,-10%);
        }
        to{
            transform: translate(-50%,-50%);
        }
    }
    h6{
        i{
            color: #e4162a;
        }
    }
    p{
        i{
            margin-left:3px;
            color: rgb(155, 47, 47);
        }
    }
    .closeing{
        position: absolute;
        top: 10px;
        left: 10px;
        font-size: 30px;
        cursor: pointer;
        &:hover{
            color: red;
        }
    }
}
.services{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    .cover{
        width: fit-content;
        margin: auto;
        color: #fff;
        border-radius: 5px;
        background-color: #166294;
        &:last-of-type{
            background-color: #053260;
        }
    }
    hr{
        width: 100%;
        height: 3px;
        background-color: #053260;
    }
    .row{
        &:nth-child(5){
            div{
                .card{
                    .card-header{
                        background-color: #053260;
                    }
                    a{
                        background-color: #053260;
                    }
                }
            }
        }
        div{
                .card{
                    .card-header{
                        background-color: #166294;
                        color: white;
                    }
                    a{
                        display: block;
                        background-color: #166294;
                    }
                }
        }
    }
    >.card{
        .card-header{
            background-color: #4d4b4b;
            color: #fff;
            display: flex;
            justify-content: center;
            align-items: center;
        }
        display: flex;
        flex-direction: row;
        a{
            display: block;
        }
    }
}
.watsapp{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    .cover{
        width: fit-content;
        margin: auto;
        color: #fff;
        border-radius: 5px;
        background-color: #3edf5b;
    }
    hr{
        width: 100%;
        height: 3px;
        background-color: #3edf5b;
    }
    .row{
        div{
                .card{
                    .card-header{
                        background-color: #3edf5b;
                        color: white;
                    }
                    .card-body{
                        p{
                            text-align: right;
                            line-height: 1.6;
                        }
                    }
                    a{
                        display: block;
                        background-color: #3edf5b;
                    }
                }
        }
    }
    >.card{
        .card-header{
            background-color: #3edf5b;
            color: #fff;
            display: flex;
            justify-content: center;
            align-items: center;
        }
        .card-body{
            p{
                text-align: right;
                line-height: 1.6;
            }
        }
        display: flex;
        flex-direction: row;
        a{
            display: block;
            background-color: #3edf5b;
        }
    }
}
.Contact{
    flex-grow: 1;
    display: flex;
    justify-content: center;
    .row{
        .right{
            padding: 15px;
            border-radius: 5px;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            background-color: #716f6f;
            color: #fff;
            a{
                text-decoration: none;
                // background-color: #716f6f;
                padding: 10px;
                border-radius: 4px;
                color: #fff;
                padding: 0;
                margin: 0;
                margin-right: auto;
            }
            .con{
                width: 100%;
                font-size: 16px;
                text-align: end;
            }
            iframe{
                margin: 0;
                width: 100%;
            }
            .sociallinks{
                height: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
                a{
                    text-decoration: none;
                    color: #0a66c2;
                    i{
                        font-size: 25px;
                    }
                }
            }
        }
        .left{
            form{
                width: 100%;
                height: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
                flex-direction: column;
                p{
                    background-color: #716f6f;
                    padding: 10px;
                    border-radius: 4px;
                    color: #fff;
                }
                input{
                    width: 49%;
                }
                textarea{
                    width: 100%;
                    flex-grow: 1;
                }
                .btn-cuton{
                    color: #fff;
                    background-color: #716f6f;
                    cursor: pointer;
                    width: 50%;
                }
            }
        }
    }
}
.privacy{
    flex-grow: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    >p{
        padding: 10px;
        border-radius: 5px;
        background-color: #716f6f;
        color: white;
    }
    .row{
        padding: 10px;
        border-radius: 10px;
        text-align: center;
        background-color: #d7d9da;
        article{
            direction: rtl;
            text-align: right;
        }
    }
}
.animatedLog{
    position: fixed;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    background-color: #fff;
    z-index: 10;
    display: flex;
    justify-content: center;
    align-items: center;
    &.active{
        animation: anim2 0.5s forwards linear;
    }
    img{
        animation: animm 0.5s forwards linear;
    }
    @keyframes animm {
        from{
            transform: scale(0);
        }
        to{
            transform: scale(1);
        }
    }
    @keyframes anim2 {
        to{
            opacity: 0;
        }
    }
}
li{
    text-align: right !important;
}
.Application{
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    z-index: 10;
    background-color: rgba(#000,0.5);
    img{
        width: 250px;
        height: 600px;
    }
    .clossss{
        font-size: 25px;
        color: #fff;
        cursor: pointer;
        &:hover{
            color: red;
        }
    }
}
.main-title {
    text-transform: uppercase;
    margin: 0 auto 80px;
    border: 2px solid black;
    padding: 10px 20px;
    font-size: 30px;
    width: fit-content;
    position: relative;
    z-index: 1;
    transition: 0.2s;
    &::before {
        content: "";
        width: 12px;
        height: 12px;
        background-color: #1884c2;
        position: absolute;
        border-radius: 50%;
        top: 50%;
        transform: translateY(-50%);
        left: -30px;
        transition: 0.4s;
        z-index: -1;
    }
    &::after {
        content: "";
        width: 12px;
        height: 12px;
        background-color: #1884c2;
        position: absolute;
        border-radius: 50%;
        top: 50%;
        transform: translateY(-50%);
        right: -30px;
        z-index: -1;
        transition: 0.4s;
    }
    &:hover{
        color: white;
        border: 2px solid white;
        transition-delay: 0.5s;
        &::before{
            left: 0;
            width: 50%;
            height: 100%;
            border-radius: 0;
        }
        &::after{
            right: 0;
            width: 50%;
            height: 100%;
            border-radius: 0;
        }
    }
}
#linkw{
    text-decoration: none;
}
.Says{
    flex-grow: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    .row{
        background-color: #716f6f;
        color: #fff;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        padding:5px 20px;
        .slick-next{
            right: -5px;
            &::before{
                color: #000;
            }
        }
        .slick-prev{
            left: -5px;
            &::before{
                color: #000;
            }
        }
        .slick-list{
            margin: 0 20px;
        }
        .custom-card{
            display: flex;
            justify-content: center;
            align-items: center;
            h3{
                text-align: center;
            }
            .custom-p{
                position: relative;
                width: fit-content;
                margin: auto;
                overflow: unset;
                &::before{
                    content: "";
                    width: 50%;
                    height: 2px;
                    background-color: #0a66c2;
                    position: absolute;
                    left: 50%;
                    transform: translateX(-50%);
                    bottom: -7px;
                    z-index: 5;
                }
            }
            .custom-pos{
                padding: 5px;
                border-radius: 5px;
                background-color: #166294;
                width: fit-content;
                margin: auto;
            }
        }
        .stars{
            display: flex;
            justify-content: space-between;
            align-items: center;
        }
        .bi-star-fill{
            color: #f7ae00;
        }
    }
}
.second-title{
    background-color: #1884c2;
    padding: 5px;
    color: #fff;
    width: fit-content;
    margin-left: auto;
    margin-right: auto;
    transform: skew(-20deg);
}
.Aboutus{
    .text-cu{
        color: #fff;
    }
    hr{
        width: 100%;
        height: 1px;
        background-color: #166294;
    }
    .row{
        justify-content: center;
        .card{
            transition: 0.3s;
            &:hover{
                background-color: #1884c2;
                color: #fff;
            }
        }
        div{
            div{
                .imgaim{
                    margin: auto;
                    width: 60%;
                    height: 200px;
                    background-color: transparent;
                }
            }
        }
        .goalicon{
            color: #0a66c2;
        }
    }
    .om{
        color: #fff;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: row;
        img{
            width: 200px !important;
            height: 200px !important;
            background-color: #0a66c2;
        }
        background-color: #716f6f;
        .om{
            background-color: transparent;
        }
    }
    .slick-next{
        right: -5px;
        &::before{
            color: #000;
        }
    }
    .slick-prev{
        left: -5px;
        &::before{
            color: #000;
        }
    }
    .slick-list{
        margin: 0 20px;
    }
    .custom-card{
        display: flex;
        justify-content: center;
        align-items: center;
        h3{
            text-align: center;
        }
    }
    .step-progress{
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        position: relative;
        span{
            display: block;
            width: 40px;
            height: 40px;
            line-height: -20px;
            font-size: 20px;
            border: 1px solid #0a66c2;
            border-radius: 50%;
            background-color: #fff;
            cursor: pointer;
            &.active{
                background-color: #0a66c2;
                color: #fff;
                font-size: 22px;
                line-height: -22px;
            }
        }
        &::before{
            content: "";
            width: calc(100% - 40px);
            height: 2px;
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%,-50%);
            background-color: #1884c2;
            z-index: -1;
        }
    }
}
.dashboard{
    flex-grow: 1;
    .eventsHandle{
        display: flex;
        justify-content: space-between;
        align-items: center;
        >div{
            img{
                width: 100%;
            }
            padding: 20px;
            width: calc((100% - 50px) / 2);
        }
    }
}
.form-bg{
    background-color: #6c757d;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    input,textarea{
        width: 300px;
    }
    textarea{
        height: 150px;
    }
    button{
        cursor: pointer;
    }
}
.handlers{
    position: relative;
    i{
        position: absolute;
        right: 30px;
        top: 20px;
        cursor: pointer;
        color: #1d9bf0;
        font-size: 30px;
        &:hover{
            color: red;
        }
    }
}
.formmember{
    background-color: #fff;
    color: #000;
    box-shadow: 0 0 3px 0 #000;
    padding: 15px;
    border-radius: 5px;
    .isal{
        border: 1px solid #1d9bf0;
        width: 250px;
        height: 250px;
    }
    .title{
        color: #1d9bf0;
    }
    p{
        i{
            color: #000;
        }
    }
    form{
        .custom-header{
            background-color: #1884c2;
            width: fit-content;
            color: #fff;
            margin-left: auto;
            margin-right: auto;
            border-radius: 5px;
        }
        label{
            color: #1d9bf0;
            span{
                color: red;
            }
        }
        input,textarea{
            border: 1px solid #1884c2 !important; 
            outline: none;
            color: #000;
            &:focus{
                outline: none;
                border: none;
            }
        }
        .form-select{
            background-position: left 0.75rem center;
        }
    }
    .colss{
        position: absolute;
        left: 20px;
        top: 20px;
        color: #1d9bf0;
        font-size: 30px;
        cursor: pointer;
        &:hover{
            color: red;
        }
    }
}
.Cv{
    flex-grow: 1;
    .active-custom{
        border-radius: 5px;
        background-color: #1884c2;
        color: #fff !important;
        .nav-link{
            color: #fff !important;
        }
    }
}
.Addcv{
    form{
        label{
            color: #1d9bf0;
            span{
                color: red;
            }
        }
        input{
            border: 1px solid #1884c2 !important; 
            outline: none;
            color: #000;
            &:focus{
                outline: none;
                border: none;
            }
            &::placeholder{
                color: #cbcccd;
            }
        }
        .form-select{
            background-position: left 0.75rem center;
            border: 1px solid #1884c2 !important; 
            outline: none;
        }
        textarea{
            border: 1px solid #1884c2 !important; 
            outline: none;
        }
    }
}
.text-nowrap{
    white-space: nowrap;
}
.text-cuto{
    color: #166294;
}
.massage{
    background-color: #0a66c2;
    position: fixed;
    padding: 20px;
    border-radius: 5px;
    box-shadow: 0 0 5px 0 #000;
    color: #fff;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    transition: 0.4s;
    i{
        position: absolute;
        left: 2px;
        top: 2px;
        cursor: pointer;
        &:hover{
            color: red;
        }
    }
    &.active{
        transform: translate(-500%, -50%);
    }
}
.massage-customs{
    background-color: #0a66c2;
    position: fixed;
    padding: 20px;
    border-radius: 5px;
    box-shadow: 0 0 5px 0 #000;
    color: #fff;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    transition: 0.4s;
    i{
        position: absolute;
        left: 2px;
        top: 2px;
        cursor: pointer;
        &:hover{
            color: red;
        }
    }
    &.active{
        transform: translate(-500%, -50%);
    }
}
.massage-custom{
    width: 300px;
    height: 340px;
    position: fixed;
    padding: 20px;
    border-radius: 5px;
    background-color:#1884c2;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    transition: 0.4s;
    p{
        padding: 0;
        margin: 0;
    }
    video{
        width: 100%;
        height: 100%;
    }
    i{
        position: absolute;
        left: 5px;
        top: 5px;
        cursor: pointer;
        &:hover{
            color: red;
        }
    }
}
.table-responsive{
        overflow: auto;
    .table{
        overflow: auto;
    }
    
}
.w-custom{
    width: 120px;
    white-space: nowrap;
    i{
        font-size: 15px;
    }
}
.deleting{
    color: #1d9bf0;
    transition: 0.4s;
    cursor: pointer;
    &:hover{
        color: red;
        font-size: 1.2rem;
    }
}
.search{
    form{
        .custom-header{
            background-color: #1884c2;
            width: fit-content;
            color: #fff;
            margin-left: auto;
            margin-right: auto;
            border-radius: 5px;
        }
        label{
            color: #1d9bf0;
            span{
                color: red;
            }
        }
        input,textarea{
            border: 1px solid #1884c2 !important; 
            outline: none;
            color: #000;
            &:focus{
                outline: none;
                border: none;
            }
        }
        .form-select{
            background-position: left 0.75rem center;
        }
    }
}
@media (min-width:992px){
    .w-lg-25{
        width: 24% !important;
    }
}
.customeventForm{
    flex-grow: 1;
    form{
        label{
            color: #1d9bf0;
            span{
                color: red;
            }
        }
        input{
            border: 1px solid #1884c2 !important; 
            outline: none;
            color: #000;
            &:focus{
                outline: none;
                border: none;
            }
            &::placeholder{
                color: #cbcccd;
            }
        }
    }
}
.form-check {
    padding: 0;
    margin: 0;
    .form-check-input {
        float: right;
        margin-right: -1.5em;
        cursor: pointer;
    }
}
.Reports{
    flex-grow: 1;
    display: flex;
    justify-content: center;
    // background-color: red;
    .rep{
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;

        .covering-image{
            position: relative;
            width: fit-content;
            height: fit-content;
            img{
                width: 100%;
                max-height: 450px;
                object-fit: contain;
            }
            .contorl{
                position: absolute;
                bottom: 0;
                left: 0;
                display: flex;
                justify-content: space-around;
                align-items: center;
                width: 100%;
                height: 100%;
                background-color: rgba(#000, 0.3);
                a{
                    color: #fff;
                    &:hover{
                        color: #1884c2 !important;
                        i{
                            color: #1884c2 !important;
                            font-size: 40px;
                        }
                    }
                    i{
                        color: #fff;
                        font-size: 30px;
                        transition: 0.4s;   
                    }
                }
            }
        }
    }
}
#watsLink{
    position: fixed;
    bottom: 5px;
    right: 5px;
    color: #3edf5b;
    cursor: pointer;
    z-index: 10000;
    // background-color: rgba(#cccccc, 0.2);
    text-shadow: 0 0 2px #3edf5b;
    padding: 5px;
    #watsIconstyle{
        width: 60px;
        height: 60px;
    }
    i{
        font-size: 50px;
    }
}
.signupForm{
    flex-grow: 1;
    position: relative;
    form{
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%);
        width: 300px;
        div{
            position: relative;
            i{
                position:absolute;
                left: 5px;
                top: 50%;
                transform: translateY(-50%);
                font-size: 20px;
                cursor: pointer;
            }
        }
    }
}
form{
    div{
        flex-wrap: wrap !important;
        div{
            &.RSPBprogressBar{
                width: 100% !important;
            }
            width: 49%;
            @media(max-width:992px) {
                &.RSPBprogressBar{
                    width: 100% !important;
                }
                width: 100%;
            }
        }
    }
}
.imag-phone{
    object-fit: contain;
    object-position: center;
    width: 200px;
    height: 250px;
    @media(max-width:992px) {
        width: 100%;
        height: 300px;
    }
}
.nobza-custom{
    max-height: 100px;
    max-width: 500px;
    overflow-x: auto;
    &::-webkit-scrollbar{
        width: 4px;
    }
    &::-webkit-scrollbar-track{
        background-color: #000;
    }
    &::-webkit-scrollbar-thumb{
        background-color: #1884c2;
    }
}